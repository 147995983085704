<template>
	<div>
		<creatsuccess :msg="showtype">
			<div class="suppierDetail">
				<el-form :model="ruleForm" :validate-on-rule-change="true" :rules="rules" ref="ruleForm"
					class="submit-form" label-width="200px">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>基本信息</span>
						</div>
						<div class="content">
							<!-- 活动名称： -->
							<el-form-item label="活动名称：" prop="ActivityName">
								<el-input v-model="ruleForm.ActivityName" :disabled="currentEditStatus==3"
									placeholder="最多输入20个字" maxlength="20" style="width:350px"></el-input>
							</el-form-item>
							<!-- 活动时间 -->
							<el-form-item label="活动时间：" required>
								<el-form-item prop="StartTime" class="el-form-date-pick">
									<el-date-picker :disabled="currentEditStatus==2||currentEditStatus==3"
										format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
										v-model="ruleForm.StartTime" type="datetime" placeholder="开始时间"
										:picker-options='maxOptions'>
									</el-date-picker>
								</el-form-item>
								<span style="margin:0 10px;">~</span>
								<el-form-item prop="EndTime" class="el-form-date-pick">
									<el-date-picker :disabled="currentEditStatus==3" format="yyyy-MM-dd HH:mm:ss"
										value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.EndTime" type="datetime"
										placeholder="结束时间" :picker-options='minOptions'>
									</el-date-picker>
								</el-form-item>
							</el-form-item>

							<el-form-item label="活动标签：" prop="ActivityTag">
								<el-input class="input-new-tag" v-model="activityFlag"
									:disabled="currentEditStatus==3||ruleForm.ActivityFlagList.length>2"
									placeholder="最多输入10个字" maxlength="10" style="width:350px"
									@keyup.enter.native="handleInputConfirm">
									<el-button slot="append" @click='handleInputConfirm'>添加</el-button>
								</el-input>
								<div>
									<el-tag :key="item+index" :type='currentEditStatus!=3?"":"info"'
										v-for="(item,index) in ruleForm.ActivityFlagList"
										:closable='currentEditStatus!=3' :disable-transitions="false"
										@close="handleClose(item)">
										{{item}}
									</el-tag>
								</div>
								<active-tag remark='活动期间显示在商品详情页、商品分享图片、商品列表，最多添加3个'></active-tag>
							</el-form-item>

						</div>
					</el-card>

					<!-- 活动信息 -->
					<el-card class="box-card" style="margin-top:20px;">
						<div slot="header" class="clearfix">
							<span>活动信息</span>
						</div>
						<el-form-item label="适用商品：" required>
							<div class="radio">
								<el-radio :disabled="currentEditStatus==3" v-model="ruleForm.ActivityRangeType"
									@change="changeRangeType" :label="0">全部商品参加</el-radio>
							</div>
							<div class="radio">
								<el-radio :disabled="currentEditStatus==3" v-model="ruleForm.ActivityRangeType"
									@change="changeRangeType" :label="1">指定商品参加</el-radio>

								<el-form-item v-if="ruleForm.ActivityRangeType==1">
									<div style="margin-bottom:10px;">
										<span v-if="currentEditStatus!=3"
											style="color:#409EFF;cursor: pointer;margin-right:10px"
											@click="handleSelectProDialog">选择商品</span>
										<el-input v-model="productSearchKey" placeholder="搜索已选商品名称、编码"
											style="width:250px;" @keyup.enter.native="handleFilterSelectes">
											<el-button slot="append" icon="el-icon-search"
												@click='handleFilterSelectes'></el-button>
										</el-input>
									</div>
									<el-table border :data="ruleForm.paginationProduct" style="width: 100%">
										<el-table-column :key="5" label="商品" width="300px">
											<template slot-scope="scope">
												<div class="dialog-name-content">
													<img :src='scope.row.ImgUrlComplete' />
													<div class="right">
														<div class="name">
															<pre
																style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}
															</pre>
														</div>
														<span>{{scope.row.State}}</span>
													</div>
												</div>
											</template>
										</el-table-column>
										<el-table-column :key="6" prop="ProductPrice" label="价格"></el-table-column>
										<el-table-column :key="7" prop="Stock" label="库存"></el-table-column>
										<el-table-column :key="8" label="操作" v-if="currentEditStatus!=3">
											<template slot-scope="scope">
												<el-button style="color:#F56C6C;" v-if="currentEditStatus!=3"
													@click="deleteSortPro(scope.row,scope.$index)" type="text"
													size="small">删除</el-button>
												<span style="color:#C0C4CC;" v-else type="text" size="small">删除</span>
											</template>
										</el-table-column>
									</el-table>
									<el-pagination v-if="proPage.total" style="margin-top:20px;float:right;"
										@size-change="handleSizeChange" @current-change="handleCurrentChange"
										:current-page="proPage.current" :page-sizes="[5,10, 20]"
										:page-size="proPage.size" layout="total, sizes, prev, pager, next, jumper"
										:total="proPage.total">
									</el-pagination>
								</el-form-item>
							</div>
							<div class="radio">
								<el-radio :disabled="currentEditStatus==3" v-model="ruleForm.ActivityRangeType"
									@change="changeRangeType" :label="2">指定商品不参加</el-radio>

								<el-form-item v-if="ruleForm.ActivityRangeType==2">
									<div style="margin-bottom:10px;">
										<span v-if="currentEditStatus!=3"
											style="color:#409EFF;cursor: pointer;margin-right:10px"
											@click="handleSelectProDialog">选择商品</span>
										<el-input v-model="productSearchKey" placeholder="搜索已选商品名称、编码"
											style="width:250px;" @keyup.enter.native="handleFilterSelectes">
											<el-button slot="append" icon="el-icon-search"
												@click='handleFilterSelectes'></el-button>
										</el-input>
									</div>
									<el-table border :data="ruleForm.paginationProduct" style="width: 100%">
										<el-table-column :key="5" label="商品" width="300px">
											<template slot-scope="scope">
												<div class="dialog-name-content">
													<img :src='scope.row.ImgUrlComplete' />
													<div class="right">
														<div class="name">
															<pre
																style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}
															</pre>
														</div>
														<span>{{scope.row.State}}</span>
													</div>
												</div>
											</template>
										</el-table-column>
										<el-table-column :key="6" prop="ProductPrice" label="价格"></el-table-column>
										<el-table-column :key="7" prop="Stock" label="库存"></el-table-column>
										<el-table-column :key="8" label="操作" v-if="currentEditStatus!=3">
											<template slot-scope="scope">
												<el-button style="color:#F56C6C;" v-if="currentEditStatus!=3"
													@click="deleteSortPro(scope.row,scope.$index)" type="text"
													size="small">删除</el-button>
												<span style="color:#C0C4CC;" v-else type="text" size="small">删除</span>
											</template>
										</el-table-column>
									</el-table>
									<el-pagination v-if="proPage.total" style="margin-top:20px;float:right;"
										@size-change="handleSizeChange" @current-change="handleCurrentChange"
										:current-page="proPage.current" :page-sizes="[5,10, 20]"
										:page-size="proPage.size" layout="total, sizes, prev, pager, next, jumper"
										:total="proPage.total">
									</el-pagination>
								</el-form-item>
							</div>
						</el-form-item>

						<el-form-item label="参与人群：" required :rules="rules.JoinMember"
							:prop="isChangeVip?'IsJoinMemberVip':'IsJoinNoMemberVip'">
							<el-checkbox :disabled="currentEditStatus==3" v-model="ruleForm.IsJoinMemberVip"
								@change="isChangeVip=true">会员</el-checkbox>
							<el-checkbox :disabled="currentEditStatus==3" v-model="ruleForm.IsJoinNoMemberVip"
								@change="isChangeVip=false">非会员</el-checkbox>
						</el-form-item>


						<el-form-item label="优惠方式：" required>
							<div class="radio">
								<el-radio :disabled="currentEditStatus==3" @change="changeIsLoopRadio"
									v-model="ruleForm.IsLoop" :label="false">阶梯满减</el-radio>
							</div>
							<div class="radio">
								<el-radio :disabled="currentEditStatus==3" @change="changeIsLoopRadio"
									v-model="ruleForm.IsLoop" :label="true">循环满减</el-radio>
							</div>
						</el-form-item>

						<el-form-item label="优惠规则：" required>
							<div class="step" v-for="(item,index) in ruleForm.Settings" :key="index"
								@click="handleCurrentIndex(index)">
								<div class="title" v-if="ruleForm.IsLoop==false" style="margin:10px;">
									<span style="float:left">{{offerRuleLevel[index]}}级优惠</span>
									<el-button v-if="index!=0&&currentEditStatus!=3"
										@click="handleSelectSettings(index)" type="text"
										style="float:right;color:#E51C23;margin-right:20px;">删除</el-button>
								</div>
								<el-form-item class="content">
									<!-- 门槛优惠 -->
									<el-form-item label="优惠门槛：" v-if="currentEditType==1" style="margin-bottom:20px;"
										:rules="rules.Condition" :prop="'Settings.'+index+'.Condition'">
										<span v-if="ruleForm.IsLoop">每满</span>
										<span v-else>消费满</span>
										<el-input type="text" :disabled="currentEditStatus==3" v-model="item.Condition"
											@input='onlyNumber(item.Condition)' style="width:200px;margin-left:5px">
										</el-input> 元
									</el-form-item>
									<el-form-item label="优惠门槛：" v-if="currentEditType==2" style="margin-bottom:20px;"
										:rules="rules.Condition" :prop="'Settings.'+index+'.Condition'">
										<span v-if="ruleForm.IsLoop">每购买</span>
										<span v-else>购买</span>
										<el-input style="margin:0 10px;width:200px;" v-model="item.Condition"
											:disabled="currentEditStatus==3"
											onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
											onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>件
									</el-form-item>
									<!-- 优惠内容 -->
									<el-form-item label="优惠内容：" required style="margin:10px 0;">
										<span
											:class="{'is-error-button':(!item.IsDicsount)&&(!item.IsFreeDelivery)&&(!item.IsGiveCoupun)&&(!item.IsGiveGift)&&(!item.IsMarkdown)&&(!item.IsGiveGrowthValue)}">
											<!-- 空标签，用于定位 -->
										</span>
										<div class="check-item">
											<el-checkbox @change="handleGiveMarkdownMoney"
												:disabled="item.IsDicsount||currentEditStatus==3"
												v-model="item.IsMarkdown">
												<el-form-item :prop="'Settings.'+index+'.MarkdownMoney'"
													:inline-message=true
													:rules="(item.IsMarkdown)?rules.MarkdownMoney:rules.none">
													减
													<input v-model="item.MarkdownMoney"
														:disabled="item.IsDicsount||currentEditStatus==3||!item.IsMarkdown"
														style="padding:0 10px;width:200px;line-height: 36px;border:1px solid #ddd;border-radius: 5px;margin:0 5px;display:inline-block;"
														@keydown="handleMarkdownMoney" @blur.prevent='MarkdownMoneyBlur'
														type="number" />
													元
												</el-form-item>
											</el-checkbox>
										</div>
										<!-- 打折 -->
										<div class="check-item">
											<el-checkbox @change="handleGiveDicsountRate" v-if="ruleForm.IsLoop==false"
												:disabled="item.IsMarkdown||currentEditStatus==3"
												v-model="item.IsDicsount">
												<el-form-item :prop="'Settings.'+index+'.DicsountRate'"
													:inline-message='true'
													:rules="(item.IsDicsount)?rules.DicsountRate:rules.none">
													打
													<el-input
														:disabled="item.IsMarkdown||currentEditStatus==3||!item.IsDicsount"
														style="margin: 5px;width:200px;" v-model="item.DicsountRate"
														@change="handleChangeDicsount(item.DicsountRate,index)">
													</el-input>
													折
												</el-form-item>
											</el-checkbox>
										</div>

										<div class="check-item">
											<el-checkbox @change="handleFreeDelivery" :disabled="currentEditStatus==3"
												v-model="item.IsFreeDelivery">
												包邮
											</el-checkbox>
											<span v-if="item.IsFreeDelivery&&currentEditStatus!=3"
												style="color:#409EFF;cursor: pointer;margin-left:20px;"
												@click="selectArea(index)">选择包邮区域</span>
											<div style="font-size:14px;color:#909399;width:900px">
												<span v-for="(tt,ii) in item.AreaNames" :key="ii">{{tt}}，</span>
											</div>
										</div>


										<div class="check-item">
											<el-checkbox :disabled="currentEditStatus==3" v-model="item.IsGivePoints"
												@change="handleGivePoints">
												<el-form-item :prop="'Settings.'+index+'.PointsCount'"
													:inline-message=true
													:rules="item.IsGivePoints?rules.PointsCount:rules.none">
													送
													<el-input :disabled="currentEditStatus==3||!item.IsGivePoints"
														style="margin: 5px;width:200px" v-model="item.PointsCount"
														onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
														onblur="this.v();if (value==0||value=='-'){value =''}">
													</el-input>积分
												</el-form-item>
											</el-checkbox>
											<div style="font-size:12px;color:#909399;margin-left:25px;">
												送积分是订单基础积分上额外赠送，如：订单本身100积分，本次设置送20积分，则该笔订单总共产生120积分。</div>
										</div>

										<div class="check-item">
											<el-checkbox :disabled="currentEditStatus==3" @change="handleGiveCoupun"
												v-model="item.IsGiveCoupun">
												送优惠券
											</el-checkbox>
											<span v-if="item.IsGiveCoupun&&currentEditStatus!=3"
												style="color:#409EFF;cursor: pointer;margin-left:20px;"
												@click="selectCoupon(index)">选择优惠券</span>
											<div style="font-size:12px;color:#909399;margin-left:25px;">
												若优惠券设置领取次数限制，客户不满足领取条件时将不再发放；若优惠券剩余数量少于赠送数量，将不再发放。请关注优惠券剩余数量并及时补充</div>
										</div>

										<!-- 优惠券列表 -->
										<!-- :prop="'Settings.'+index+'.paginationCoupons'" :inline-message=true  :rules="item.IsGiveCoupun?rules.paginationCoupons:rules.none" -->
										<el-form-item v-if="item.IsGiveCoupun">
											<el-table border :data="item.paginationCoupons" style="width: 100%">
												<el-table-column :key="9" label="优惠券" width="300px">
													<template slot-scope="scope">
														<div class="dialog-name-content">
															<div class="right">
																<div class="name">{{scope.row.CouponName}}</div>
																<span>{{scope.row.Remark}}</span>
															</div>
														</div>
													</template>
												</el-table-column>
												<el-table-column :key="10" prop="UseTypeValue" label="类型">
												</el-table-column>
												<el-table-column :key="11" label="赠送数量/人">
													<template slot-scope="scope">
														<el-input :disabled="currentEditStatus==3"
															@input="numbersChangeCoupon(scope.row,scope.$index)"
															style="margin:0 10px;width:100px;"
															v-model="scope.row.CouponCount"
															onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
															onblur="this.v();if (value==0||value=='-'){value =''}">
														</el-input>
													</template>
												</el-table-column>
												<el-table-column :key="12" prop="CouponContent" label="优惠内容">
												</el-table-column>
												<el-table-column :key="13" label="操作">
													<template slot-scope="scope" v-if="currentEditStatus!=3">
														<el-button style="color:#F56C6C;" v-if="currentEditStatus!=3"
															@click="deleteSortCoupon(scope.row,scope.$index)"
															type="text" size="small">删除</el-button>
														<span style="color:#C0C4CC;" v-else type="text"
															size="small">删除</span>
													</template>
												</el-table-column>
											</el-table>
											<el-pagination v-if="couponsPage[index].total"
												style="margin-top:20px;float:right;"
												@size-change="handleCouponsSizeChange"
												@current-change="handleCouponsCurrentChange"
												:current-page="couponsPage[index].current" :page-sizes="[5,10, 20]"
												:page-size="couponsPage[index].size"
												layout="total, sizes, prev, pager, next, jumper"
												:total="couponsPage[index].total">
											</el-pagination>

										</el-form-item>

										<div class="check-item">
											<el-checkbox :disabled="currentEditStatus==3" @change="handleGiveGift"
												v-model="item.IsGiveGift">
												送赠品
											</el-checkbox>
											<span v-if="item.IsGiveGift&&currentEditStatus!=3"
												style="color:#409EFF;cursor: pointer;margin-left:20px;"
												@click="selectGift(index)">选择商品</span>
											<div style="font-size:12px;color:#909399;margin-left:25px;">
												若商品剩余库存少于赠送数量，将不再发放赠品。请关注商品库存并及时补充</div>
										</div>

										<!-- 赠品列表 -->
										<el-form-item>
											<el-table border v-if="item.IsGiveGift" :data="item.paginationGifts"
												style="width: 100%">
												<el-table-column :key="14" label="商品" width="300px">
													<template slot-scope="scope">
														<div class="dialog-name-content">
															<img :src='imgUrl+scope.row.ImgUrl' />
															<div class="right">
																<div class="name">{{scope.row.Name}}</div>
																<span>{{scope.row.Remark}}</span>
															</div>
														</div>
													</template>
												</el-table-column>
												<el-table-column :key="15" prop="ProductSpec" label="规格">
													<template slot-scope="scope">
														<div v-if="scope.row.ProductSpec==''">默认规格</div>
														<div v-else>{{scope.row.ProductSpec}}</div>
													</template>
												</el-table-column>
												<el-table-column :key="16" prop="ProductPrice" label="价格">
												</el-table-column>
												<el-table-column :key="17" prop="Stock" label="库存"></el-table-column>
												<el-table-column :key="18" label="赠送数量/人">
													<template slot-scope="scope">
														<el-input :disabled="currentEditStatus==3"
															@input="numbersChangeGift(scope.row,scope.$index)"
															style="margin:0 10px;width:100px;"
															v-model="scope.row.ProductCount"
															onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
															onblur="this.v();if (value==0||value=='-'){value =''}">
														</el-input>
													</template>
												</el-table-column>
												<el-table-column :key="19" label="操作" v-if="currentEditStatus!=3">
													<template slot-scope="scope">
														<el-button style="color:#F56C6C;" v-if="currentEditStatus!=3"
															@click="deleteSortGift(scope.row,scope.$index)" type="text"
															size="small">删除</el-button>
														<span style="color:#C0C4CC;" v-else type="text"
															size="small">删除</span>
													</template>
												</el-table-column>
											</el-table>
											<el-pagination v-if="giftsPage[index].total"
												style="margin-top:20px;float:right;"
												@size-change="handleGiftsSizeChange"
												@current-change="handleGiftsCurrentChange"
												:current-page="giftsPage[index].current" :page-sizes="[5,10, 20]"
												:page-size="5" layout="total, sizes, prev, pager, next, jumper"
												:total="giftsPage[index].total">
											</el-pagination>
										</el-form-item>

										<!-- //赠送成长值 -->
										<div class="check-item">
											<el-checkbox :disabled="currentEditStatus==3"
												v-model="item.IsGiveGrowthValue" @change="handleGiveGrowthValue">
												<el-form-item :prop="'Settings.'+index+'.GiveGrowthValue'"
													:inline-message=true
													:rules="item.IsGiveGrowthValue?rules.GiveGrowthValue:rules.none">
													送
													<el-input :disabled="currentEditStatus==3||!item.IsGiveGrowthValue"
														style="margin: 5px;width:200px" v-model="item.GiveGrowthValue"
														onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
														onblur="this.v();if (value==0||value=='-'){value =''}">
													</el-input>成长值
												</el-form-item>
											</el-checkbox>
											<div style="font-size:12px;color:#909399;margin-left:25px;">
												送成升值是消费赠送成长值上额外赠送，如：消费1元赠送100成长值，本次设置送200成长值，客户消费1元，则该笔订单总共产生300成长值。
											</div>
										</div>
									</el-form-item>
								</el-form-item>
							</div>
							<div class="step"
								v-if="(ruleForm.Settings.length<=4&&ruleForm.IsLoop==false&&currentEditStatus!=3)">
								<div class="title add-step" @click="addDiscount">
									添加{{offerRuleLevel[ruleForm.Settings.length]}}级优惠</div>
							</div>
						</el-form-item>

					</el-card>
				</el-form>

				<!-- 已去除 -->
				<!-- <link-modal :showLiveTab="true" :visible="vipLinkVisible" @change="vipLinkChanged" @cancel="vipLinkVisible=false"></link-modal> -->

				<div class="footer" v-if="currentEditStatus!=3">
					<el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
					<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">
						保存</el-button>
				</div>
				<div class="footer" v-if="currentEditStatus==3">
					<el-button style="width:240px;" @click="suppilerBack">返回</el-button>
				</div>
			</div>

		</creatsuccess>

		<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
			<select-produce api="activityMarkdownGiftChoseProduct" :isShowCheck='ruleForm.ActivityRangeType'
				:couponData="couponData"
				:params="['KeyWord', 'ProductType', 'ProductGroupId', 'ProductBrandId', 'OnlyChoosable','ActivityCouponId','AcctivityRangeType',]"
				:selectedData="ruleForm.ProductList" @getSelectList="getSelectList" :visible.sync="selectProDialog"
				v-if="selectProDialog"></select-produce>
		</el-dialog>

		<!-- 选择优惠券 -->
		<el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="1000px" class="dialog data-dialog">
			<select-coupon api="activityMarkdownGiftChoseCoupon" :couponData="couponData"
				:params="['CouponName', 'ActivityCouponUseType']"
				:selectedData="ruleForm.Settings[currentIndex].Coupons" @getSelectList="getSelectCouponList"
				:visible.sync="selectCouponShow" v-if="selectCouponShow"></select-coupon>
		</el-dialog>

		<!-- 选择赠品 -->
		<el-dialog title="选择赠品" :visible.sync="selectGiftShow" width="1300px" class="dialog">
			<select-gift api="productSpecPopList" :couponData="couponData" :isShowSpecValue='true'
				:params="['KeyWords', 'ProductType', 'ProductGroupId', 'ProductBrandId', 'OnlyChoosable','ActivityCouponId','AcctivityRangeType','IsGift']"
				:selectedData="ruleForm.Settings[currentIndex].Gifts" @getSelectList="getSelectGiftList"
				:visible.sync="selectGiftShow" v-if="selectGiftShow"></select-gift>
		</el-dialog>

		<!-- 选择赠品,此方法对应@/components/SelectMulProduce/specNoConflict.vue组件，效果一致 -->
		<!-- <el-dialog title="选择赠品" :visible.sync="selectGiftShow" width="1300px" class="dialog">
				<select-gift :selectedData="ruleForm.Settings[currentIndex].Gifts" @getSelectList="getSelectGiftList" :visible.sync="selectGiftShow"
				v-if="selectGiftShow"></select-gift>
			</el-dialog> -->

		<el-dialog title="选择包邮区域" :visible.sync="selectShipShow" width="900px" v-if="selectShipShow" @opened="muneFun">
			<select-ship :defaultData='ruleForm.Settings[currentIndex].AreaIds||[]' @getCheckedArea='getCheckedArea'>
			</select-ship>

		</el-dialog>

		<!-- 保存商品冲突 -->
		<el-dialog title="以下商品与其他活动冲突，请重新设置" :visible.sync="conflictGoodsShow" width="1000px"
			class="dialog data-dialog">
			<div>
				<el-table border :data="conflictGoodsList" style="max-height:450px;overflow: auto;">
					<el-table-column :key="20" label="商品" width="300px">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<img :src='imgUrl+scope.row.ImgUrl' />
								<div class="right">
									<div class="name">
										{{scope.row.Name}}
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :key="21" prop="Remark" label="冲突信息"></el-table-column>
				</el-table>
			</div>
			<div style="text-align:center;margin-top:20px;">
				<el-button class="button" style="width:200px" @click="conflictGoodsShow=false" type="primary">确定
				</el-button>
			</div>
		</el-dialog>




	</div>
</template>

<script>
	import selectProduce from './SelectMulPro'
	import selectGift from './SelectMulGift'
	// import selectGift from '@/components/SelectMulProduce/specNoConflict.vue'
	import selectCoupon from '@/components/SelectMultipCoupon'
	import selectShip from './selectShipDialog.vue';
	import activeTag from '../components/activeTag'
	import config from '@/config/index'
	import apiList from '@/api/other'
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
	import axios from 'axios';
	import {
		format
	} from 'path';
	import {
		type
	} from 'os'
	// import linkModal from '@/views/components/linkModal';
	export default {
		components: {
			selectProduce,
			selectCoupon,
			selectShip,
			selectGift,
			activeTag,
			creatsuccess
			// linkModal
		},
		data() {
			var checkGrowthValue = (rule, value, callback) => {
				if (!value || value == '' || value == '0') {
					callback(new Error('请输入成长值'));
				} else if (Number(value) > 99999 || Number(value) < 1) {
					callback(new Error('成长值请输入1~99999之间，整数'));
				} else {
					callback()
				}
			};
			var checkActivityName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入活动名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			var checkFullReduce = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请输入优惠内容'));
				} else {
					callback()
				}
			};
			var checkIntegral = (rule, value, callback) => {
				if (!value || value == '' || value == '0') {
					callback(new Error('请输入积分'));
				} else {
					callback()
				}
			};
			var checkDicsountRate = (rule, value, callback) => {
				if (value === 0 || value > 10 || value < 1) {
					return callback(new Error('折扣请设置在1-9.9折之间，最多1位小数'));
				} else {
					if (!value) {
						return callback(new Error('请输入优惠内容'));
					} else {
						return callback();
					}
				}
			};
			var checkPagination = (rule, value, callback) => {};
			var checkJoinMember = (rule, value, callback) => {
				if (!this.ruleForm.IsJoinMemberVip && !this.ruleForm.IsJoinNoMemberVip) {
					return callback(new Error('请选择参与人群'));
				} else {
					return callback();
				}
			};
			var checkNone = (rule, value, callback) => {
				callback();
			};
			return {
				handleMarkdownMoney(e) {
					e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
				},
				handleChangeDicsount(value, index) {
					value = (value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
					if (value > 9.9) {
						value = null
					} else if (value < 1) {
						value = null
					}
					this.ruleForm.Settings[index].DicsountRate = value
				},
				ConditionInput(e) {
					e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
					if (e.target.value.length == 1) {
						e.target.value = (e.target.value.match(/^\+?[0-9]\d*$/)) || null
					}
				},
				checkedAll: [false, false, false, false, false],
				baseAPI: config.BASE_URL,
				imgUrl: config.IMG_BASE,
				loading: false,
				paginationCouponList: [],
				paginationGiftList: [],
				activityFlag: '',
				ruleForm: {
					ActivityFlagList: [],
					ActivityName: '',
					StartTime: '',
					EndTime: '',
					ActivityRangeType: 0,
					ProductList: [],
					IsLoop: false,
					content: '',
					IsJoinMemberVip: true,
					IsJoinNoMemberVip: true,
					Settings: [{
						Condition: '',
						IsDicsount: false,
						DicsountRate: '',
						IsMarkdown: false,
						MarkdownMoney: '',
						IsFreeDelivery: false,
						shippingAreaList: [],
						IsGivePoints: false,
						PointsCount: '',
						IsGiveGrowthValue: false,
						GiveGrowthValue: '',
						IsGiveCoupun: false,
						Coupons: [],
						IsGiveGift: false,
						Gifts: [],
						AreaIds: [],
						AreaNames: []
					}],
				},
				proPage: {
					current: 1,
					total: 0,
					size: 5
				},
				couponsPage: [{
					total: 0,
					current: 1,
					size: 5
				}],
				giftsPage: [{
					total: 0,
					current: 1,
					size: 5,
				}],
				page: {
					total: 0,
					current: 1,
					size: 5
				},
				currentCount: '',

				isChangeVip: true,

				rules: {
					ProductList: [{
						required: true,
						message: '至少选择一个商品',
						trigger: 'change'
					}],
					ActivityName: [{
						required: true,
						// message: '请输入活动名称',
						trigger: 'blur',
						validator: checkActivityName
					}],
					StartTime: [{
						required: true,
						message: '请完善开始时间',
						trigger: 'blur'
					}],
					EndTime: [{
						required: true,
						message: '请完善结束时间',
						trigger: 'blur'
					}],
					count: [{
						required: true,
						message: '请输入优惠券总量',
						trigger: 'blur'
					}],
					ActivityRangeType: [{
						required: true,
						message: '请选择商品',
						trigger: 'change'
					}],
					Condition: [{
						required: true,
						message: '请输入优惠门槛',
						trigger: 'blur'
					}],
					MarkdownMoney: [{
						trigger: 'blur',
						validator: checkFullReduce
					}],
					DicsountRate: [{
						trigger: 'blur',
						validator: checkDicsountRate
					}],
					PointsCount: [{
						trigger: 'blur',
						validator: checkIntegral
					}],
					GiveGrowthValue: [{
						trigger: 'blur',
						validator: checkGrowthValue
					}],
					paginationCoupons: [{
						trigger: 'blur',
						validator: checkPagination
					}],
					JoinMember: [{
						trigger: 'change',
						validator: checkJoinMember
					}],
					none: [{
						trigger: 'blur',
						validator: checkNone
					}]
				},
				// dialog
				productSearchKey: '',
				selectedFilterList: [],
				selectProDialog: false,
				selectCouponShow: false,
				selectGiftShow: false,
				selectShipShow: false,
				conflictGoodsShow: false,
				conflictGoodsList: [],
				menuList: [],
				AreaIds: [],
				ProductIdList: [],
				CouponIdList: [],
				GiftIdList: [],
				offerRuleLevel: ["一", "二", "三", "四", "五"],
				checkedList: [],
				currentEditType: 0,
				currentEditId: '',
				currentEditCopyId: null,
				currentEditStatus: null,
				couponData: {
					id: '',
					range: 0,
					StartTime: '',
					EndTime: ''
				},
				currentIndex: 0,
				canSaveFlag: true,

				vipLinkVisible: false,

				showtype: {
					issuccess: false,
					id: 0,
					type: 0,
					suctype: 2,
				},
			}
		},
		created() {
			this.currentEditType = this.$route.query.activeType
			this.currentEditId = this.$route.query.id
			this.currentEditStatus = this.$route.query.status
			this.currentEditCopyId = this.$route.query.copyId
			if (this.currentEditId || this.currentEditCopyId) {
				this.getData()
			}
			// 传值给选择商品的组件
			this.couponData.id = this.$route.query.id
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		beforeMount() {
			this.getDistrict();
		},
		methods: {
			getDistrict(){
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v=' + new Date().getTime()).then(res => {
					this.menuList = res.data;
				})
			},
			handleInputConfirm() {
				let inputValue = this.activityFlag;
				if (inputValue) {
					this.ruleForm.ActivityFlagList.push(inputValue);
				}
				this.activityFlag = '';
			},
			handleClose(tag) {
				this.ruleForm.ActivityFlagList.splice(this.ruleForm.ActivityFlagList.indexOf(tag), 1);
			},
			ConditionBlur(e) {
				if (e.target.value == '0' || e.target.value == 0) {
					this.ruleForm.Settings[this.currentIndex].Condition = null
				}
			},
			onlyNumber(obj) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1');
				obj = obj.replace(/[^\d.]/g, "");
				obj = obj.replace(/\.{2,}/g, ".");
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
				if (obj.indexOf(".") < 0 && obj != "") {
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				// 通过正则过滤小数点后两位，大于0
				this.ruleForm.Settings[this.currentIndex].Condition = obj
			},
			MarkdownMoneyBlur(e) {

				if (e.target.value == '0' || e.target.value == 0) {
					this.ruleForm.Settings[this.currentIndex].MarkdownMoney = null
				}
			},
			numbersChangeCoupon(record, index) {
				let CouponCount = Number(record.CouponCount)
				if (CouponCount > record.LeaveCount) {
					this.ruleForm.Settings[this.currentIndex].Coupons[index].CouponCount = ''
					this.$message({
						showClose: true,
						type: 'error',
						message: '赠送数量超出限制，请重新设置'
					})
				}
			},
			numbersChangeGift(record, index) {
				let ProductCount = Number(record.ProductCount)
				console.log("ProductCount", ProductCount, record.Stock)
				if (ProductCount > record.Stock) {
					this.ruleForm.Settings[this.currentIndex].Gifts[index].ProductCount = ''
					this.$message({
						showClose: true,
						type: 'error',
						message: '赠送数量超出限制，请重新设置'
					})
				}
			},
			selectCurrentAll() {
				if (this.checkedAll[this.currentIndex]) {
					this.ruleForm.Settings[this.currentIndex].AreaIds = this.menuList.map(item => {
						return item.id
					})
				} else {
					this.ruleForm.Settings[this.currentIndex].AreaIds = []
					this.$refs.tree.setCheckedKeys([]);
				}
			},
			getCheckedArea(ids) {
				this.ruleForm.Settings[this.currentIndex].AreaIds = ids
			},
			//数据处理
			initForm() {
				this.ruleForm.Settings.forEach((t, index) => {
					t.DicsountRate = t.DicsountRate == 0 ? '' : t.DicsountRate
					t.PointsCount = t.PointsCount == 0 ? '' : t.PointsCount
					t.MarkdownMoney = t.MarkdownMoney == 0 ? '' : t.MarkdownMoney

					//优惠规则，优惠券和赠品分页
					this.couponsPage[index] = {
						size: 5,
						current: 1,
						total: t.Coupons.length
					}
					this.giftsPage[index] = {
						size: 5,
						current: 1,
						total: t.Gifts.length
					}
					let pagination1 = t.Coupons.slice(0, 5);
					t.paginationCoupons = pagination1
					let pagination2 = t.Gifts.slice(0, 5);
					t.paginationGifts = pagination2
				})

				//商品分页
				this.proPage.total = this.ruleForm.ProductList.length;
				this.selectedFilterList = this.ruleForm.ProductList
				let pagination = this.selectedFilterList.slice(0, 5);
				this.ruleForm.paginationProduct = pagination

				this.couponData.range = this.ruleForm.ActivityRangeType
				if (this.currentEditCopyId) {
					this.ruleForm.StartTime = ''
					this.ruleForm.EndTime = ''
				}

				this.$forceUpdate();
			},
			changeIsLoopRadio(e) {
				this.ruleForm.Settings = [{
					Condition: '',
					IsDicsount: false,
					DicsountRate: '',
					IsMarkdown: false,
					MarkdownMoney: '',
					IsFreeDelivery: false,
					shippingAreaList: [],
					IsGivePoints: false,
					PointsCount: '',
					IsGiveCoupun: false,
					Coupons: [],
					CouponsIdList: [],
					IsGiveIdGift: false,
					Gifts: [],
					GiftsList: [],
					IsGiveGrowthValue: false,
					GiveGrowthValue: ''
				}]
			},
			changeRangeType(e) {
				this.couponData.range = e
				this.ruleForm.ProductList = []
				this.selectedFilterList = []
				this.ruleForm.paginationProduct = []
				this.productSearchKey = ''
				this.proPage = {
						current: 1,
						total: 0,
						size: 5
					},
					this.$forceUpdate()

			},
			// 编辑时初始数据
			async getData() {
				this.loading = true;
				let id = this.currentEditId == undefined ? this.currentEditCopyId : this.currentEditId
				try {
					let data = {
						ActivityFullId: id
					}
					let result = await apiList.activityMarkdownGiftModel(data);
					if (result.Result) {
						this.ruleForm = result.Result
					}
					this.initForm()
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			// 取消
			suppilerCansel() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？', '提示', {
					confirmButtonText: '确认离开',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/market/discountActivity/index'
					});
				})
			},
			suppilerBack() {
				this.$router.push({
					path: '/market/discountActivity/index'
				});
			},

			//选择商品
			handleSelectProDialog() {
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' ||
						this.ruleForm
						.EndTime == null)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					this.couponData.StartTime = this.ruleForm.StartTime
					this.couponData.EndTime = this.ruleForm.EndTime
					this.selectProDialog = true
				}
			},
			// 保存，处理数据
			saveSupiler(ruleForm) {
				//处理选择的商品Id
				this.ProductIdList = []
				this.ruleForm.ProductList.forEach(item => {
					this.ProductIdList.push(item.Id)
				})
				// 处理选择的赠品Id和优惠券Id
				this.ruleForm.Settings.forEach(t => {
					t.CouponsId = []
					t.Coupons.forEach(tt => {
						t.CouponsId.push({
							CouponId: tt.Id,
							CouponCount: tt.CouponCount
						})
					})
					t.GiftsId = []
					t.Gifts.forEach(tt => {
						t.GiftsId.push({
							ProductId: tt.ProductId || tt.Id,
							ProductSpecId: tt.ProductSpecId,
							ProductCount: tt.ProductCount
						})
					})
				})
				console.log("	this.ruleForm.Settings", this.ruleForm.Settings)
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
						if (this.ruleForm.ActivityRangeType != 0 && this.ProductIdList.length == 0) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '商品至少选择1件'
							});
							return false
						}

						let choosed = 1
						this.ruleForm.Settings.forEach(t => {
							let status = (!t.IsDicsount) && (!t.IsFreeDelivery) && (!t.IsGiveCoupun) &&
								(!t.IsGiveGift) && (!t.IsMarkdown) && (!t.IsGivePoints) && (!t
									.IsGiveGrowthValue)
							if (status) {
								choosed += 1
							}
						})
						if (choosed > 1) {
							this.$common.completeFormInformation('请选择优惠内容', 'is-error-button')
							return false
						}
						this.verifyAuthGift()
					} else {
						this.$common.completeFormInformation('请完善活动信息', 'is-error')
						return false;
					}
				})
			},
			// 验证参与的商品中有没有分销礼包
			async verifyAuthGift() {
				try {
					let data = {
						ActivityFullId: this.currentEditId,
						ActivityRangeType: this.ruleForm.ActivityRangeType,
						ProductList: this.ProductIdList,
					}
					let result = await apiList.verifyAuthGift(data);
					if (result.Result) {
						this.$confirm('温馨提示：参与本场活动的商品中，包含分销礼包商品，是否确认继续添加？', {
							confirmButtonText: '确认',
							cancelButtonText: '关闭',
							type: 'warning'
						}).then(() => {
							if (this.canSaveFlag) {
								this.supilerData()
							}
						}).catch(() => {
							this.$message({
								showClose: true,
								type: 'info',
								message: '已取消保存'
							});
						}).finally(() => {})
					} else {
						if (this.canSaveFlag) {
							this.supilerData()
						}
					}
				} catch (e) {
					console.log("e", e)
				} finally {

				}
			},
			// 保存
			async supilerData() {
				this.canSaveFlag = false
				this.loading = true
				this.conflictGoodsList = []
				try {
					let data = {
						ActivityFullId: this.currentEditId,
						ConditionType: this.currentEditType,
						ActivityName: this.ruleForm.ActivityName,
						StartTime: this.ruleForm.StartTime,
						EndTime: this.ruleForm.EndTime,
						ProductList: this.ProductIdList,
						ActivityRangeType: this.ruleForm.ActivityRangeType,
						IsLoop: this.ruleForm.IsLoop,

						ActivityFlagList: this.ruleForm.ActivityFlagList,
						IsJoinMemberVip: this.ruleForm.IsJoinMemberVip,
						IsJoinNoMemberVip: this.ruleForm.IsJoinNoMemberVip,

						ActivityMarkdownGiftSettingList: this.ruleForm.Settings
					}
					let result = await apiList.activityMarkdownGiftAdd(data);
					if (result.Result && result.Result.length) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '操作失败'
						});
						this.conflictGoodsList = result.Result
						this.conflictGoodsShow = true
					} else {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						if (this.$route.query.copyId || !this.$route.query.id) {
							this.showtype = {
								issuccess: true,
								id: result.Result.Id,
								type: this.$route.query.activeType,
								suctype: 2,
							}
						} else {
							this.$router.push({
								path: '/market/discountActivity/index'
							});
						}
					}
					this.loading = false;
				} catch (error) {
					console.log(error);
					this.loading = false
					this.canSaveFlag = true
				} finally {
					this.loading = false;
					this.canSaveFlag = true
				}
			},
			selectInit(row, id) {
				return row.dis
			},
			//获取当前点击的是哪一个序列号
			handleCurrentIndex(index) {
				this.currentIndex = index
			},
			// 增加等级优惠
			addDiscount() {
				this.ruleForm.Settings.push({
					Condition: '',
					IsDicsount: false,
					DicsountRate: '',
					IsMarkdown: false,
					MarkdownMoney: '',
					IsFreeDelivery: false,
					shippingAreaList: [],
					IsGivePoints: false,
					PointsCount: '',
					IsGiveCoupun: false,
					Coupons: [],
					CouponsIdList: [],
					IsGiveIdGift: false,
					Gifts: [],
					GiftsList: [],
					IsGiveGrowthValue: false,
					GiveGrowthValue: ''
				})
				this.couponsPage.push({
					total: 0,
					size: 10,
					current: 1,
				})
				this.giftsPage.push({
					total: 0,
					size: 10,
					current: 1,
				})
			},
			handleSelectSettings(index) {
				this.$confirm('是否确定删除此优惠等级？ ', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ruleForm.Settings.splice(index, 1)
				})
				this.$forceUpdate()
			},
			// 
			saveSelectArea() {
				this.checkedList = this.$refs['selectShipDialog'].getCheckedList();
				this.selectShipShow = false
			},
			muneFun() {
				let that = this;
			},
			selectArea(index) {
				this.currentIndex = index
				this.selectShipShow = true
			},
			getCheckedArea(ids, names) {
				this.ruleForm.Settings[this.currentIndex].AreaIds = ids
				this.ruleForm.Settings[this.currentIndex].AreaNames = names;
				this.selectShipShow = false
			},
			// 确定事件
			submitEdit() {
				// this.jurisdictionList = [];
				let index = this.currentIndex
				this.ruleForm.Settings[index].AreaIds = [];
				this.ruleForm.Settings[index].AreaNames = [];
				let arrs = this.$refs.tree.getCheckedNodes();

				this.selectShipShow = false;
				arrs.forEach(item => {
					this.ruleForm.Settings[index].AreaIds.push(item.id)
					this.ruleForm.Settings[index].AreaNames.push(item.name)
				})


			},
			changeIntegralCheck(e) {
				this.$forceUpdate()
			},
			//获取选择的商品的列表
			getSelectList(data) {
				this.productSearchKey = ''
				this.ruleForm.ProductList = this.selectedFilterList = data
				this.selectProDialog = false
				this.handleFilterSelectes()
			},
			//打开选择优惠券弹框
			selectCoupon(index) {
				this.selectCouponShow = true
			},
			unique(arr) {
				const res = new Map();
				return arr.filter((arr) => !res.has(arr.ProductId) && res.set(arr.ProductId, 1))
			},
			//获取选择的优惠券列表
			getSelectCouponList(data) {
				let index = this.currentIndex
				this.selectCouponShow = false
				if (this.ruleForm.Settings[index].Coupons.length) {
					let temp = this.ruleForm.Settings[index].Coupons.map(item => item.Id)
					let resArr = data.filter(item => !temp.includes(item.Id))
					resArr.forEach(t => {
						t.CouponCount = ''
					})
					resArr.forEach(t => {
						this.ruleForm.Settings[index].Coupons.push(t)
					})
				} else {
					data.forEach(t => {
						t.CouponCount = ''
					})
					this.ruleForm.Settings[index].Coupons = data;
				}
				this.paginationCoupon()
			},
			//切换选择满减check
			handleGiveMarkdownMoney(e) {
				if (!e) {
					this.ruleForm.Settings[this.currentIndex].MarkdownMoney = ''
				}
			},
			//切换选择打折check
			handleGiveDicsountRate(e) {
				if (!e) {
					this.ruleForm.Settings[this.currentIndex].DicsountRate = ''
				}
			},
			//切换选择包邮check
			handleFreeDelivery(e) {
				if (!e) {
					this.ruleForm.Settings[this.currentIndex].AreaNames = []
					this.checkedList = []
					this.ruleForm.Settings[this.currentIndex].AreaIds = []
				}
			},
			//切换选择送积分
			handleGivePoints(e) {
				if (!e) {
					this.ruleForm.Settings[this.currentIndex].PointsCount = ''
				}
			},
			//切换选择成长值
			handleGiveGrowthValue(e) {
				if (!e) {
					this.ruleForm.Settings[this.currentIndex].GiveGrowthValue = ''
				}
			},
			//切换选择优惠券check
			handleGiveCoupun(e) {
				if (!e) {
					this.ruleForm.Settings[this.currentIndex].Coupons = []
					this.ruleForm.Settings[this.currentIndex].paginationCoupons = []
				}
			},

			//打开选择赠品弹框
			selectGift(index) {
				// if(this.ruleForm.StartTime==''||this.ruleForm.EndTime==''){
				//   this.$message({showClose: true,
				//     type:'error',
				//     message:'请先完善活动时间'
				//   })
				// }else{
				this.couponData.StartTime = this.ruleForm.StartTime
				this.couponData.EndTime = this.ruleForm.EndTime
				this.selectGiftShow = true
				// }
			},
			//获取选择的赠品列表
			getSelectGiftList(data) {
				this.selectGiftShow = false
				let index = this.currentIndex
				if (this.ruleForm.Settings[index].Gifts.length) {
					let temp = this.ruleForm.Settings[index].Gifts.map(item => item.ProductSpecId)
					let resArr = data.filter(item => !temp.includes(item.ProductSpecId))
					resArr.forEach(t => {
						this.ruleForm.Settings[index].Gifts.push(t)
					})
				} else {
					this.ruleForm.Settings[index].Gifts = data
				}
				this.ruleForm.Settings[index].Gifts.forEach(t => {
					t.ProductSpec = t.hasOwnProperty('ProductSpec') ? t.ProductSpec : t.Spce
					t.ProductPrice = t.hasOwnProperty('ProductPrice') ? t.ProductPrice : t.Price
					t.ProductId = t.hasOwnProperty('ProductId') ? t.ProductId : t.Id
				})
				this.paginationGift()
			},
			//切换选择赠品check
			handleGiveGift(e) {
				if (!e) {
					this.ruleForm.Settings[this.currentIndex].Gifts = []
					this.ruleForm.Settings[this.currentIndex].paginationGifts = []
					this.giftsPage[this.currentIndex].total = 0
				}
			},

			// 删除指定商品
			deleteSortPro(val, index) {

				this.ruleForm.ProductList = this.ruleForm.ProductList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})

				this.selectedFilterList = this.selectedFilterList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})

				if ((this.selectedFilterList.length) % this.proPage.size == 0 && this.proPage.current > 1) {
					this.proPage.current = this.proPage.current - 1;
				}


				this.paginationPro()

			},
			deleteSortCoupon(val, index) {

				this.ruleForm.Settings[this.currentIndex].Coupons = this.ruleForm.Settings[this.currentIndex].Coupons
					.filter(t => {
						if (t.Id != val.Id) {
							return t
						}
					})
				if ((this.ruleForm.Settings[this.currentIndex].Coupons.length) % this.couponsPage[this.currentIndex]
					.size == 0 &&
					this.couponsPage[this.currentIndex].current > 1) {
					this.couponsPage[this.currentIndex].current = this.couponsPage[this.currentIndex].current - 1;
				}
				this.paginationCoupon()

			},
			deleteSortGift(val, index) {

				this.ruleForm.Settings[this.currentIndex].Gifts = this.ruleForm.Settings[this.currentIndex].Gifts.filter(
					t => {
						if (t.ProductSpecId != val.ProductSpecId) {
							return t
						}
					})
				if ((this.ruleForm.Settings[this.currentIndex].Gifts.length) % this.giftsPage[this.currentIndex].size ==
					0 && this.giftsPage[
						this.currentIndex].current > 1) {
					this.giftsPage[this.currentIndex].current = this.giftsPage[this.currentIndex].current - 1;
				}
				this.paginationGift()
			},
			handleFilterSelectes() {
				this.proPage.current = 1
				this.selectedFilterList = []
				this.ruleForm.ProductList.filter(t => {
					if ((t.Name.indexOf(this.productSearchKey) != -1) || (t.ProductNo && (t.ProductNo.indexOf(this
							.productSearchKey) != -1))) {
						this.selectedFilterList.push(t)
					}
				})
				this.paginationPro()
			},
			//product分页
			paginationPro() {
				this.proPage.total = this.selectedFilterList.length;
				let start = (this.proPage.current - 1) * this.proPage.size
				let end = start + this.proPage.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.ruleForm.paginationProduct = pagination
				this.$forceUpdate()
			},
			handleSizeChange(val) {
				this.proPage.size = val;
				this.paginationPro()
			},
			handleCurrentChange(val) {
				this.proPage.current = val;
				this.paginationPro()
			},
			//coupon分页
			paginationCoupon() {
				this.couponsPage[this.currentIndex].total = this.ruleForm.Settings[this.currentIndex].Coupons.length
				let start = (this.couponsPage[this.currentIndex].current - 1) * this.couponsPage[this.currentIndex].size
				let end = start + this.couponsPage[this.currentIndex].size
				let pagination = this.ruleForm.Settings[this.currentIndex].Coupons.slice(start, end);
				this.ruleForm.Settings[this.currentIndex].paginationCoupons = pagination
				this.$forceUpdate()

			},
			//gift分页
			paginationGift() {
				this.giftsPage[this.currentIndex].total = this.ruleForm.Settings[this.currentIndex].Gifts.length
				let start = (this.giftsPage[this.currentIndex].current - 1) * this.giftsPage[this.currentIndex].size
				let end = start + this.giftsPage[this.currentIndex].size
				let pagination = this.ruleForm.Settings[this.currentIndex].Gifts.slice(start, end);
				this.ruleForm.Settings[this.currentIndex].paginationGifts = pagination
				this.$forceUpdate()
			},
			handleCouponsSizeChange(val) {
				this.couponsPage[this.currentIndex].size = val
				this.paginationCoupon()
			},
			handleCouponsCurrentChange(val) {
				this.couponsPage[this.currentIndex].current = val
				this.paginationCoupon()
			},
			handleGiftsSizeChange(val) {
				this.giftsPage[this.currentIndex].size = val
				this.paginationGift()
			},
			handleGiftsCurrentChange(val) {
				this.giftsPage[this.currentIndex].current = val
				this.paginationGift()
			}
		}
	}
</script>

<style lang="less" scoped>
	.suppierDetail {
		margin-bottom: 90px;

		.remarks {
			color: #bbb;
			font-size: 14px;
		}

		.parent-box {
			position: relative;

			.close-bosx {
				position: absolute;
				right: -5px;
				top: -13px;
				font-size: 18px;
			}
		}

		.markRed {
			position: absolute;
			left: -94px;
			top: 5px;
			color: red;
			font-size: 18px;
		}
	}

	.table-container {
		position: relative;
		overflow: hidden;
	}

	.el-tag {
		margin-right: 10px;
	}

	.step {
		width: 100%;

		.title {
			overflow: hidden;
			background-color: #F0F2F5;
			padding-left: 20px;
		}

		.add-step {
			color: #409EFF;
		}
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}
	}

	.area-select-footer {
		margin-right: 50px;
		z-index: 999;
		// float: right;
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}

	.check-item {
		display: inline-block;
		width: 80%;
		margin-bottom: 10px;
	}

	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input:disabled {
		background-color: #F5F7FA;
		border-color: #E4E7ED;
		color: #C0C4CC;
	}

	.number__input {
		border: 1px solid #ccc;
	}

	.footer {
		box-shadow: 13px 1px 6px #999;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		text-align: center;
		z-index: 999;
	}
</style>
