<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="商品名称、编码"></el-input>
			</div>
			<!--销售类型  -->
			<div class="filter-item">
				<label class="label">销售类型: </label>
				<el-select v-model="type" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in typeOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- 销售分组 -->
			<div class="filter-item">
				<label class="label">商品分组: </label>
				<el-select v-model="group" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in groupOptions" :key="index" :label="item.GroupName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- 商品品牌 -->
			<div class="filter-item">
				<label class="label">商品品牌: </label>
				<el-select v-model="brand" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in brandOptions" :key="index" :label="item.BrandName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
				<!-- <el-checkbox v-if="isShowCheck" v-model="checkedShopPro" @change="showOptionalPro">仅看可选商品</el-checkbox> -->
				<!-- <el-checkbox  v-model="checkedShopPro" >仅看可选商品</el-checkbox> -->
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table height="450" :data="tableDataList" v-loading="loading" style="width: 100%" ref="compSelectProTable"
			 @select-all='selectAll' :row-key="getRowKeys" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :selectable='selectInit' :reserve-selection="true">
				</el-table-column>
				<el-table-column prop="Name" label="商品" width="300">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img :src='imgUrl+scope.row.ImgUrl' />
							<div class="right">
								<div class="name">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="销售类型">
					<template slot-scope="scope">
						<span>{{scope.row.ProductType==0? '单品销售': '组合销售'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="规格">
					<template slot-scope="scope">
						<div v-if="scope.row.Spce==''">默认规格</div>
						<div v-else>{{scope.row.Spce}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="Price" label="价格"></el-table-column>
				<el-table-column prop="Stock" label="库存"></el-table-column>
				<el-table-column prop="Remark" label="备注" width="200px"></el-table-column>
			</el-table>

			<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedAll" @change="selectCurrentAll">当前页全选</el-checkbox>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
	import {
		productBrandList,
		productGroupList,
	} from "@/api/goods.js";

	import apiList from "@/api/other.js"

	import config from '@/config/index'

	export default {
		name: 'selectGift',
		props: {
			api: String, //弹框商品列表请求接口名称, 统一加在 api/popGoods.js里
			selectedData: { //需要选中的商品列表
				type: Array,
				default: () => {
					return []
				}
			},
			couponData: {
				type: Object,
				default: () => {
					return {}
				}
			},
			params: { //自定义请求参数
				type: Array,
				default: () => {
					return []
				}
			},
			isShowCheck: {
				type: Boolean,
				default: () => {
					return true
				}
			},
			isShowSpecValue: {
				type: Boolean,
				default: () => {
					return false
				}
			}
		},
		data() {
			return {
				baseAPI: config.BASE_URL,
				imgUrl: config.IMG_BASE,
				searchKey: '',
				type: null,
				typeOptions: [{
						value: 0,
						label: '单品销售'
					},
					{
						value: 1,
						label: '组合销售'
					}
				],
				group: null,
				groupOptions: [],
				brand: null,
				brandOptions: [],
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				selectCount: 0,
				multipleSelection: [],
				checkedShopPro: true,

				loading: false
			}
		},
		created() {

			this.getInfor();
			this.getSingleList()

		},
		watch: {},
		computed: {
			checkedAll() {
				var val = this.multipleSelection;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var disabledLen = 0;
				var ids = val.map(item => {
					return item.ProductSpecId
				})
				this.tableDataList.forEach(item => {
					if (ids.indexOf(item.ProductSpecId) > -1 || item.IsDisabled) {
						ableSelectLength++;
					}
					if (item.IsDisabled) {
						disabledLen++;
					}
				});
				return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength && disabledLen != ableSelectLength) ?
					true : false;
			}
			
		},
		mounted() {
		},
		methods: {

			//筛选数据
			async getInfor() {
				try {
					let result = await productBrandList()
					this.brandOptions = result.Result

					let result1 = await productGroupList()
					this.groupOptions = result1.Result

				} catch (e) {
					console.log(e)
				} finally {

				}
			},


			async getSingleList() {
				this.loading = true;
				try {
					var params = this.params;
					let data = {
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
						KeyWord: this.searchKey,
						ProductType: this.type,
						ProductGroupIds: this.group,
						ProductBrandId: this.brand,
						OnlyChoosable: this.checkedShopPro,
						IsPage: true,
						IsGift: true,
						StartTime: this.couponData.StartTime,
						EndTime: this.couponData.EndTime,
						ActivityFullId: this.couponData.id
					};
					let result = await apiList.productSpecPopList(data)
					this.page.total = result.Result.Total;
					this.tableDataList = result.Result.Results;

				} catch (error) {
					console.log(error);
				} finally {
					if (!this.isReady) {
						this.selectedData.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
					this.loading = false;
				}
			},
			//table原生全选按钮是否选中
			selectAll(e) {},
			//外部全选当前页
			selectCurrentAll(e) {
				this.$refs['compSelectProTable'].toggleAllSelection()
			},
			//当前页全选
			handleSelectionChange(val) {
				// console.log(val)
				this.multipleSelection = val;

			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getSingleList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getSingleList()
			},
			selectInit(row, id) {
					return !row.IsDisabled
			},
			clearSelection() {
				this.$nextTick(() => {
					this.$refs.compSelectProTable.clearSelection();
				});
			},
			getRowKeys(row) {
				return row.ProductSpecId;
			},
			handleFilter() {
				this.page.current = 1;
				this.getSingleList();
			},
			//查看可选商品
			showOptionalPro(e) {},
			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {
				if (!this.multipleSelection.length) {
					this.$message({showClose: true,
						message: '请选择商品',
						type: 'info'
					})
					return
				}
				this.$emit('getSelectList', this.multipleSelection)
			}

		}
	}
</script>
<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}
	}
</style>
